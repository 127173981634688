export default {
    "pt": {
        "Adesão": {
            "Cartão": {
                "Após o registo com a eVaz Energy, quanto tempo demora até receber o cartão?": "O cartão é expedido no dia útil seguinte por correio azul e demora em média até 3 dias úteis, dependendo do tempo de entrega por parte dos CTT.",
                "O cartão tem algum custo associado? E anuidade?": "Não, o cartão é gratuito, não tem custos de adesão, não tem qualquer anuidade nem taxas de utilização associadas. ",
                "O cartão tem prazo de validade ou tem carregamentos obrigatórios?": "Não, o cartão é válido permanentemente e não são necessários carregamentos obrigatórios",
                "Quantos cartões tem o cliente por direito?": "Oferecemos um cartão por viatura, excepções a serem avaliadas.",
                "Com o cartão CEME eVaz Energy posso carregar em qualquer posto de carregamento da rede Mobi.E?": "Sim, com o nosso cartão pode carregar em qualquer posto de carregamento da rede pública de Portugal Continental e Ilhas.",
                "O cartão funciona em Postos de Carregamento rápido (PCR) e em Postos Carregamento Normal (PCN)?": "Sim, o cartão funciona em qualquer posto de carregamento.",
                "O cartão já está activo ou precisa de algum código?": "O cartão já está activo e não precisa de nenhum código"
            },
        },
        "Faturação": {
            "Preços": {
                "Qual o preço da energia (em kWh ou por minuto)?": "Pode consultar os preços dos nossos tarifários na nossa página de comparação no menu de navegação Soluções > Soluções Públicas > Planos Move"
            },
            "Fatura": {
                "Fornecem facturação detalhada com todos os carregamentos efectuados?": "Quando efectuar um carregamento receberá uma fatura até ao 10º dia útil do mês seguinte. Juntamente com a fatura é enviado o detalhe da fatura e o detalhe dos consumos.",
                "Como é composto o custo associado a cada carregamento?": "1. Custo da energia consumida (que é devido ao comercializador de energia (CEME) com quem tem o contrato); 2. A tarifa de operação em vigor em cada um dos postos de carregamento estará indicada no respetivo posto e poderá ser sempre consultada no mapa do site da MOBI.E e na app da MOBI.E. Este custo é pago ao CEME que posteriormente irá pagar exatamente este valor ao OPC respetivo; 3. Taxas: IEC (imposto especial sobre consumo) e IVA."
            },
            "TAR": {
                "Como é calculada a TAR no preço final?": "Poderá ver no plano mobilidade a variação do preço da TAR. Contudo após a entrada em vigor de uma TAR (tarifa de acesso à rede) variável  em média tensão(MT) e baixa tensão (BT), dependente da instalação de postos de carregamento, optamos por simplificar o nosso tarifário, disponibilizando apensas a informação do preço com a TAR incluída."
            },
            "Débito": {
                "Como é feito o débito dos carregamentos efectuados?": "O pagamento será por débito direto pelo IBAN fornecido no registo.",
                "Com que periodicidade é feito o débito direto?": "Apenas é debitado quando consumir"
            }
        },
        "Parcerias": {
            "Alfa Energia": {
                "Como funciona a parceria Alfa Energia?": "Para poder usufruir da parceria terá que efectivar o registo no site da Alfa Energia aqui com o mesmo NIF do registo na eVaz Energy.",
                "Qual é o valor do desconto?": "Se aderir ao plano residencial Alfa Energia, também aplicável em empresas, terá o desconto de 1 cêntimo por kWh. Pode consultar os preços atualizados nas páginas dos planos disponíveis no Menu > Soluções > Planos MOVE",
                "Como sei que o desconto será aplicado terei que infomar ambas as empresas?": "Quando efectuar o registo o desconto será aplicado após confirmação de ambas as entidades"
            }
        },
        "Soluções Privadas": {
            "PowerPacks": {
                "Em que consiste esta solução?": "As novas soluções inovadoras de carregamento privado, vão permitir o retorno do investimento, a poupança de impostos e um aumento considerável da sustentabilidade da mobilidade elétrica, implementando a economia circular e a reutilização das baterias dos automóveis eléctricos.",
                "Qual a diferença do PowerPacks e dos PowerBoxes?": "Os PowerPacks incluem Painéis, Carregadores, Baterias. As PowerBoxes incluem apenas as baterias",
                "Como posso aderir ou saber mais informação?": "Pode indicar os seus dados e na pré reserva e contactá-lo-emos para saber mais detalhes, de forma a podermos elaborar um orçamento",
                "Já tenho painéis ou carregador posso aderir?": "Sim, os orçamentos são adaptados a cada cliente, neste caso apenas indicamos a compra das PowerBoxes."
            }
        }
    },
    "en": {
        "Adesão": {
            "Cartão": {
                "Após o registo com a eVaz Energy, quanto tempo demora até receber o cartão?": "O cartão é expedido no dia útil seguinte por correio azul e demora em média até 3 dias úteis, dependendo do tempo de entrega por parte dos CTT.",
                "O cartão tem algum custo associado? E anuidade?": "Não, o cartão é gratuito, não tem custos de adesão, não tem qualquer anuidade nem taxas de utilização associadas. ",
                "O cartão tem prazo de validade ou tem carregamentos obrigatórios?": "Não, o cartão é válido permanentemente e não são necessários carregamentos obrigatórios",
                "Quantos cartões tem o cliente por direito?": "Oferecemos um cartão por viatura, excepções a serem avaliadas.",
                "Com o cartão CEME eVaz Energy posso carregar em qualquer posto de carregamento da rede Mobi.E?": "Sim, com o nosso cartão pode carregar em qualquer posto de carregamento da rede pública de Portugal Continental e Ilhas.",
                "O cartão funciona em Postos de Carregamento rápido (PCR) e em Postos Carregamento Normal (PCN)?": "Sim, o cartão funciona em qualquer posto de carregamento.",
                "O cartão já está activo ou precisa de algum código?": "O cartão já está activo e não precisa de nenhum código"
            },
        },
        "Faturação": {
            "Preços": {
                "Qual o preço da energia (em kWh ou por minuto)?": "Pode consultar os preços dos nossos tarifários na nossa página de comparação no menu de navegação Soluções > Soluções Públicas > Planos Move"
            },
            "Fatura": {
                "Fornecem facturação detalhada com todos os carregamentos efectuados?": "Quando efectuar um carregamento receberá uma fatura até ao 10º dia útil do mês seguinte. Juntamente com a fatura é enviado o detalhe da fatura e o detalhe dos consumos.",
                "Como é composto o custo associado a cada carregamento?": "1. Custo da energia consumida (que é devido ao comercializador de energia (CEME) com quem tem o contrato); 2. A tarifa de operação em vigor em cada um dos postos de carregamento estará indicada no respetivo posto e poderá ser sempre consultada no mapa do site da MOBI.E e na app da MOBI.E. Este custo é pago ao CEME que posteriormente irá pagar exatamente este valor ao OPC respetivo; 3. Taxas: IEC (imposto especial sobre consumo) e IVA."
            },
            "TAR": {
                "Como é calculada a TAR no preço final?": "Poderá ver no plano mobilidade a variação do preço da TAR. Contudo após a entrada em vigor de uma TAR (tarifa de acesso à rede) variável  em média tensão(MT) e baixa tensão (BT), dependente da instalação de postos de carregamento, optamos por simplificar o nosso tarifário, disponibilizando apensas a informação do preço com a TAR incluída."
            },
            "Débito": {
                "Como é feito o débito dos carregamentos efectuados?": "O pagamento será por débito direto pelo IBAN fornecido no registo.",
                "Com que periodicidade é feito o débito direto?": "Apenas é debitado quando consumir"
            }
        },
        "Parcerias": {
            "Alfa Energia": {
                "Como funciona a parceria Alfa Energia?": "Para poder usufruir da parceria terá que efectivar o registo no site da Alfa Energia aqui com o mesmo NIF do registo na eVaz Energy.",
                "Qual é o valor do desconto?": "Se aderir ao plano residencial Alfa Energia, também aplicável em empresas, terá o desconto de 0.01 cent e o tarifário passará a 18 cêntimos/KW fora-de-vazio e 13 cêntimos/KW em vazio.",
                "Como sei que o desconto será aplicado terei que infomar ambas as empresas?": "Quando efectuar o registo o desconto será aplicado após confirmação de ambas as entidades"
            }
        },
        "Soluções Privadas": {
            "PowerPacks": {
                "Em que consiste esta solução?": "As novas soluções inovadoras de carregamento privado, vão permitir o retorno do investimento, a poupança de impostos e um aumento considerável da sustentabilidade da mobilidade elétrica, implementando a economia circular e a reutilização das baterias dos automóveis eléctricos.",
                "Qual a diferença do PowerPacks e dos PowerBoxes?": "Os PowerPacks incluem Painéis, Carregadores, Baterias. As PowerBoxes incluem apenas as baterias",
                "Como posso aderir ou saber mais informação?": "Pode indicar os seus dados e na pré reserva e contactá-lo-emos para saber mais detalhes, de forma a podermos elaborar um orçamento",
                "Já tenho painéis ou carregador posso aderir?": "Sim, os orçamentos são adaptados a cada cliente, neste caso apenas indicamos a compra das PowerBoxes."
            }
        }
    }
}