import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    margin: 0 5%;
`

const Title = styled.h3`
    font-size: 16px;
    font-weight: 500;
`

const Text = styled.p`
    font-size: 14px;
    font-weight: 400;
`

const Entry = styled.div`
    margin-bottom: 50px;
`

const BigTitle = styled.h2`
    color: ${props => props.theme.default.colors.primary};
    font-size: 24px;
    font-weight: 500;
`

export default function FaqContent({contentState, topicState}) {
    const [content, setContent] = contentState
    const [selectedTopic, setSelectedTopic] = topicState


    return (
        <Container>
            <BigTitle>{selectedTopic}</BigTitle>
            
            {Object.entries(content).map((entry, i) => <Entry key={i}>
                <Title>{entry[0]}</Title>
                <Text>{entry[1]}</Text>
            </Entry>)}
        </Container>
    )
}
