import React from 'react'
import styled from 'styled-components'
import Category from './Category'

const Container = styled.div`` 

const NavList = styled.div`
    display: flex;
    flex-direction: column;
`


export default function Nav({faqs, topicState, contentState}) {
    return (
        <Container>
            <NavList>
                {Object.entries(faqs).map((entry, i) => <Category title={entry[0]} entries={entry[1]} key={i} topicState={topicState} contentState={contentState}/>)}
            </NavList>
        </Container>
    )
}
