import React, { useContext } from 'react'
import { useState } from 'react';
import styled from 'styled-components';
import Layout from '../../../components/layout'
import FaqContent from '../../../components/layout/faqs/FaqContent';
import Nav from '../../../components/layout/faqs/Nav';
import Footer from '../../../components/layout/Footer';
import HeroContainer from '../../../components/layout/HeroContainer'
import SEO from '../../../components/seo';
import { AppContext } from '../../../contexts/AppContext';
import faqs from '../../../utils/faqs';

const LayoutSplitter = styled.div`
    display: flex;
    margin: 0 10% 100px 10%;
`

const NavContainer = styled.div`
    margin-top: 66px;
    flex: 1;
`

const Content = styled.div`
    flex: 3;
`

const Warning = styled.p`
    font-weight: 500;
    color: lightsalmon;
    margin: 0 10% 50px 10%;
    text-align: center;
`


export default function Faqs() {

    let faqStruct;
    const { appState } = useContext(AppContext)
    const [selectedTopic, setSelectedTopic] = useState("")
    const [content, setContent] = useState({
        "Bem-vindo": "A nossa página de Questões Frequentes disponibiliza-lhe algumas das questões mais frequentes que nos são colocadas pelos nossos clientes e potenciais clientes, sendo atualizada ao longo do tempo. Do lado esquerdo dispõe de um menu de seleção onde pode navegar pelos vários tópicos."
    })
    const topicState = [selectedTopic, setSelectedTopic]
    const contentState = [content, setContent]

    switch(appState.language) {
        case "PT": 
            faqStruct = faqs.pt
            break;
        case "EN":
            faqStruct = faqs.en
            break;
    }

    return (
        <Layout>
            <SEO title={appState.i18n.faqs.title} />
            <HeroContainer  title={appState.i18n.faqs.title} subtitle={appState.i18n.faqs.text} id="faqs" />
            {appState.language == "EN" && <Warning>We're working on making available the FAQs in English, we apologize for the inconvenience</Warning>}
            <LayoutSplitter>
                <NavContainer>
                    <Nav faqs={faqStruct} topicState={topicState} contentState={contentState} />
                </NavContainer>
                <Content>
                    <FaqContent contentState={contentState} topicState={topicState}/>
                </Content>
            </LayoutSplitter>
            <Footer />
        </Layout>
    )
}
