import React from 'react'
import styled from 'styled-components'
import mobility from '../../images/plans/mobility.jpg'
import residential from '../../images/plans/residential.jpg'
import volt from '../../images/plans/volt.jpg'
import powerpack from '../../images/plans/powerpack.jpg'
import powerbox from '../../images/plans/powerbox.jpg'
import endtoend from '../../images/private_charging/endtoend.png'
import publicCharging from '../../images/public_charging/hero.png'
import circularEconomy from '../../images/circular_economy/circular.jpg'



const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    min-height: calc(70px + 50vh);
    padding: calc(70px + 5vw) 10% 70px 10%;
    @media(max-width:767px) {
        flex-direction: column-reverse;
    }
`

const HalfContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 5% 0 5% 0%;
`

const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
`


const TitleContainer = styled.div`
    display: flex;
    flex-direction: column; 
    max-width: 600px;
    margin-left: 6%;
`

const SmallTitle = styled.h4`
    font-size: 1.1vw;
    font-weight: 300;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 5pt;
    margin-inline-end: 0;
    @media(max-width: 767px) {
        font-size: 12pt;
        margin-inline-start: 0;
    }
`

const Title = styled.h1`
    color: #A5D65D !important;
    font-size: clamp(24pt, 5vw, 56pt);
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: -2pt;
    margin-inline-end: 0;
    margin-bottom: 20px;
    line-height: clamp(20pt, 5vw, 56pt);
    text-transform: uppercase;
    @media(max-width: 767px) {
        line-height: 32pt;
        font-size: 24pt;
    }

`

const Subtitle = styled.h2`
    font-size: clamp(12pt, 1.4vw, 18pt);
    font-weight: 300;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;

    @media(max-width: 767px) {
        font-size: 12pt;
    }
`

const Image = styled.div`
    width: 24vw;
    height: 24vw;
    min-width: 250px;
    min-height: 250px;
    background-image: ${props => `url(${props.img})`};
    background-position: 0cm;
    border-radius: 100%;
    background-size: cover;
`

export default function HeroContainer({title, subtitle, id}) {

    let image;
    switch(id) {
        case "mobility":
            image = mobility;
            break;
        case "residential":
            image = residential;
            break;
        case "volt":
            image = volt;
            break;
        case "powerpack":
            image = powerpack;
            break;
        case "powerbox":
            image = powerbox;
            break;
        case "public-charging":
            image = publicCharging;
            break;
        case "private-charging":
            image = endtoend;
            break;
        case "circular-economy":
            image = circularEconomy;
            break;
    }

    return (
        <Wrapper>
            <HalfContainer>
                <TitleContainer>
                    {/* <SmallTitle>Plano</SmallTitle> */}
                    <Title>{title}</Title>
                    <Subtitle>{subtitle}</Subtitle>
                </TitleContainer>
            </HalfContainer>
            <ImageContainer>
                <Image img={image}></Image>
            </ImageContainer>
        </Wrapper>
    )
}
