import React, { useContext } from "react";
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppContext } from "../../contexts/AppContext";
import finance from "../../images/footer/POCentro_PT2020.svg"
import styled from "styled-components";


const Container = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 2rem 4rem;
    background-color: var(--primary-color);
    display: flex;
    color: white;

    @media(max-width: 726px) {
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }
`

const FinancingFooterImg = styled.img`
    max-width: 400px;
    height: auto;

    @media(max-width: 726px) {
        width: 300px;
    }
`

const MiddleContainer = styled.div`
    order: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
`


const EdgesContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    `

const FirstContainer = styled(EdgesContainer)`
    flex-direction: column;
    order: 1;
`

const LastContainer = styled(EdgesContainer)`
    order: 3;
    justify-content: end;
    align-items: center;
`

const EvazTrademark = styled.div`
    padding: 20px 5px 5px 5px;
`


function Footer() {

    const { appState, sendEvent } = useContext(AppContext);

    return (
        <Container>
            <FirstContainer>
                <span>geral@evazenergy.pt</span>
                <span className="contactNumber">{appState.i18n["footer"]["contactNumber"]}</span>
                <span>{appState.i18n["footer"]["contactHours"]}</span>
                <a onClick={ () => sendEvent("Navigation", "Footer", "Complaint Book") } target="_blank" rel="noopener noreferrer" className="link" href="https://www.livroreclamacoes.pt/">{appState.i18n.footer.complaintBook}</a>
            </FirstContainer>

            <MiddleContainer>
                <a href="https://storage.googleapis.com/evazenergy-public/euro_funding/ficha_de_projeto_2024.pdf" target="_blank"><FinancingFooterImg src={finance} /></a>
                <EvazTrademark>&copy; {new Date().getFullYear()} eVaz Energy</EvazTrademark>
            </MiddleContainer>
            
            <LastContainer>
                {/* <FontAwesomeIcon className="footer-social-icon" icon={faInstagram} /> */}
                <a onClick={ () => sendEvent("Navigation", "Footer", "Facebook") } target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/evazzenergy/"><FontAwesomeIcon className="footer-social-icon" icon={faFacebook} /></a>
            </LastContainer>
        </Container>
    );
}

export default Footer;