import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppContext } from '../../../contexts/AppContext'

const Container = styled.div`
`

const Title = styled.h3`
    font-size: 12px;
    text-transform: uppercase;
`

const Topic = styled.h4`
    font-size: 16px;
    font-weight: 400;
    color: ${props => props.theme.default.colors.primary};
    cursor: pointer;
`



export default function Category({title, entries, topicState, contentState}) {
    const [selectedTopic, setSelectedTopic] = topicState;
    const [content, setContent] = contentState
    const { sendEvent } = useContext(AppContext);

    const onTopicClick = (entry) => {
        setSelectedTopic(entry[0])
        setContent(entry[1])
        sendEvent("FAQs", "Topic Click", entry[0])
    }

    return (
        <Container>
            <Title>{title}</Title>
            {Object.entries(entries).map((entry, i) => <Topic onClick={() => onTopicClick(entry)} key={i}>{entry[0]}</Topic>)}
        </Container>
    )
}
